<template>
  <div class="topicSelectionDetail">
    <div class="topicSelectionDetail_title">
      {{ data.title }}
    </div>
    <div class="topicSelectionDetail_main">
      <div class="topicSelectionDetail_main_doctor">
        <div
          class="topicSelectionDetail_doctor"
          v-for="(doctorInfo, index) in data.doctor_data"
          :key="index"
        >
          <img
            class="topicSelectionDetail_doctor_avatar"
            :src="doctorInfo.professional_cover"
            alt=""
          />
          <div
            class="topicSelectionDetail_doctor_name"
            style="margin-top: 0.5rem; font-weight: bold"
          >
            {{ doctorInfo.name }}
          </div>
          <div class="topicSelectionDetail_doctor_name">
            {{ doctorInfo.hospital }}
          </div>
        </div>
      </div>
      <div class="topicSelectionDetail_doctor_item">
        当前点赞量：{{ data.like_num }}
      </div>
      <div class="topicSelectionDetail_doctor_item" style="margin-bottom: 0">
        当前排名：{{ data.rank_top }}
      </div>
      <div class="topicSelectionDetail_btn">
        <CustomButton
          size="small"
          :type="this.data.my_num >= 10 ? 'info' : primary"
          @click="handleLike"
        >
          <span>我要点赞</span>
        </CustomButton>
        <CustomButton size="small" type="success" @click="handleForward">
          <span>转发</span>
        </CustomButton>
      </div>
      <div class="tips">注: 每人每天最多点10次，每个会议每天最多点赞10次</div>
    </div>
    <div class="topicSelectionDetail_summary" v-if="false">
      <div class="topicSelectionDetail_summary_title">会议纪要</div>
      <div class="topicSelectionDetail_summary_content">
        <div style="text-indent: 4ch">
          {{ data.meet_summary }}
        </div>
        <div
          v-for="(questionsInfo, questionsIndex) in data.meet_questions"
          :key="questionsIndex"
        >
          {{ questionsInfo.question_number }}、{{ questionsInfo.question }}
        </div>
        <div style="text-indent: 4ch">
          这次讨论不仅促进了学术交流，也为患者带来了更多希望和可能。
        </div>
      </div>
    </div>
    <div class="topicSelectionDetail_video_block">
      <video
        :poster="require('@/assets/images/video_cover.png')"
        :src="data.tencent_video_urls"
        ref="videoRef"
        @ended="playEnd"
        controlslist="nodownload"
        preload="none"
        playsinline
        webkit-playsinline
      ></video>
      <div
        class="advanced_video_control_panel"
        v-if="!isPlay"
        @click="mashPlayer"
      >
        <div class="advanced_video_control_play">
          <div class="advanced_video_control_icon_bg" ref="moduleRef"></div>
          <img
            class="advanced_video_control_icon"
            :src="require('@/assets/images/common/play.png')"
          />
        </div>
      </div>
    </div>
    <div class="tips" style="text-align: start; margin-top: 0.3rem">
      注: 本会议仅供医疗人士学习
    </div>

    <div class="returnBack">
      <CustomButton size="small" @click="returnBack">
        <span>返回评选首页</span>
      </CustomButton>
    </div>
    <div
      class="share_tips_container"
      v-if="showShareMask"
      @click="showShareMask = false"
    >
      <img :src="require('@/assets/images/common/share.png')" />
    </div>
  </div>
</template>

<script >
import CustomButton from "@/components/unit/CustomButton";
import wx from "@/common/weixin";
import weixin from "weixin-js-sdk";
export default {
  name: "topicSelectionDetail",
  components: {
    CustomButton,
  },
  data() {
    return {
      showShareMask: false,
      isPlay: false,
      data: {
        doctor_data: [],
      },
    };
  },
  mounted() {
    this.getInFo();
  },
  methods: {
    getInFo() {
      let url = this.$tools.getURL(this.$urls.topicSelection.detail, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        this.data = res.data;
        this.data.doctor_data = [
          ...res.data.ROLE_ZHUXI,
          ...res.data.ROLE_JIANGZHE,
          ...res.data.ROLE_TAOLUN,
        ];

        this.share();
      });
    },

    playEnd() {
      this.timeline.num = 0;
      this.isPlay = !this.$refs.videoRef.paused;
      this.isPlay = false;
    },
    mashPlayer() {
      this.isPlay = true;
      this.playVideo();
    },
    playVideo() {
      this.$refs.videoRef.play();
      this.$refs.videoRef.controls = true;
    },
    share() {
      let params = {
        title: this.data.title,
        desc: "骨转百讯-肿瘤免疫临床实践热门话题评选活动火热进行中，快来点赞吧",
        url: "",
        logo: "https://files.medflying.com/files/骨转百讯/560/png/骨转百讯公众号头像-23_11_20.png",
      };
      // console.log(params);
      weixin.ready(() => {
        wx.sharePageMessage(params);
      });
      // wx.sharePageMessage(params);
    },
    handleForward() {
      this.showShareMask = true;
    },
    handleLike() {
      if (this.data.my_num >= 10)
        return this.$tips.error({ text: "您今日点赞已达上限，明天再来点赞吧" });
      let url = this.$tools.getURL(this.$urls.topicSelection.post_like, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then(() => {
        // this.data.my_num += 1;
        this.$tips.success({ text: "点赞成功" });
        console.log("object :>> ", this.data.my_num);
        this.getInFo();
      });
    },
    returnBack() {
      this.$router.push({
        path: "/topicSelectionList",
      });
      // this.$router.back();
    },
  },
};
</script>

<style >
.topicSelectionDetail {
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}
.topicSelectionDetail_title {
  border: 1px solid #e64638;
  text-align: center;
  padding: 0.5rem;
  font-weight: bold;
  color: #e64638;
  font-size: 1rem;
  border-radius: 0 0 1rem 1rem;
}
.topicSelectionDetail_main {
  border: 1px solid #e64638;
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.4rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0.3rem 0.05rem #a0a0a0;
}
.topicSelectionDetail_main_doctor {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.4rem;
}
.topicSelectionDetail_doctor {
  width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topicSelectionDetail_doctor_avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #e64638;
}
.topicSelectionDetail_doctor_name {
  white-space: pre-wrap;
  margin-bottom: 0.4rem;
  text-align: center;
  font-size: 0.8rem;
}

.topicSelectionDetail_doctor_item {
  color: #e64638;
  /* font-weight: bold; */
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
}

.topicSelectionDetail_btn {
  margin: 0.4rem 3rem 0.4rem;
  display: flex;
  justify-content: space-between;
}
.topicSelectionDetail_btn .customButton_container {
  flex: 60% 0 0;
  font-size: 1rem !important;
}
.topicSelectionDetail_btn .customButton_container:last-child {
  flex: 30% 0 0;
  margin-left: 1rem;
}
.topicSelectionDetail_video_block {
  width: 100%;
  position: relative;
}
.topicSelectionDetail_video_block video {
  width: 100%;
  /* object-fit: contain; */
  /* height: auto; */
}
.advanced_video_control_panel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.advanced_video_control_play {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}
.advanced_video_control_icon_bg {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.advanced_video_control_icon {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.topicSelectionDetail .tips {
  text-align: center;
  font-size: 0.8rem;
  /* padding-bottom: 0.4rem; */
}

.returnBack {
  text-align: center;
  margin-top: 1rem;
  padding: 0 4rem;
}
.returnBack .customButton_container {
  font-size: 1rem !important;
}

.share_tips_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #00000080;
}
.share_tips_container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.topicSelectionDetail_summary {
  margin-bottom: 1rem;
  font-size: 0.8rem;
}
.topicSelectionDetail_summary_title {
  background: #e64638;
  color: #fff;
  width: 30%;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 1rem;
}
.topicSelectionDetail_summary_content {
  border: 1px solid #e64638;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.4rem;
  line-height: 1.7;
}
</style>